//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      id: '',
      status: '', //1： 等待退款处理 2：同意申请，等待买家退货  3：买家已发货，等待收货 4:已收货，确认退款 5:退款成功 6:退款关闭 7同意退款，仅退款 8:拒绝(驳回)
      info: {},
      showHandleSales: !1,
      handle_state: 20,
      handle_message: '',
      goods_info: [],
      addressList: [],
      shipper_id: '',
    };
  },
  watch: {
    showHandleSales(val) {
      if (val && !this.addressList.length && this.info.refund_type == 2) {
        this.$axios.post(this.$api.set.address, { rows: 10000 }).then(res => {
          if (res.code == 0) {
            this.addressList = res.result.list;
          }
        });
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.getRefundInfo();
  },
  methods: {
    //处理仅退款&退货退款发送地址
    comfirmHandleSales() {
      let data = {
        id: this.id,
        handle_state: this.handle_state,
      };
      if (this.handle_state == 10) {
        data.handle_message = this.handle_message;
        if (!data.handle_message)
          return this.$message({
            message: '请填写驳回原因',
            type: 'warning',
          });
      }
      if (this.info.refund_type == 2 && this.handle_state == 20) {
        if (!this.shipper_id)
          return this.$message({
            message: '请选择退货地址',
            type: 'warning',
          });
        data.shipper_id = this.shipper_id;
      }
      if (this.handle_state == 10) {
        this.$axios.post(this.$api.order.handleRefund, data).then(res => {
          if (res.code == 0) {
            this.getRefundInfo();
            this.showHandleSales = !1;
            this.$message.success('处理成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.info.is_close == 0 && this.info.handle_time > 0) {
        this.$axios.post(this.$api.order.refund, { id: this.id }).then(res => {
          if (res.code == 0) {
            this.getRefundInfo();
            this.showHandleSales = !1;
            this.$message({
              message: '退款成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$axios
          .post(this.$api.order.handleRefund, data)
          .then(res => {
            if (res.code == 0) {
              if (this.info.refund_type == 2) {
                this.getRefundInfo();
                this.showHandleSales = !1;
                this.$message.success('处理成功');
              } else {
                //调用退款
                return this.$axios.post(this.$api.order.refund, { id: this.id });
              }
            } else {
              this.$message.error(res.msg);
            }
          })
          .then(res => {
            if (res.code == 0) {
              this.getRefundInfo();
              this.showHandleSales = !1;
              this.$message({
                message: '退款成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    //处理退货退款
    handleSales(val) {
      if (this.status == 3) {
        //商家收货
        this.$confirm(`请确认是否收货并退款？`, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.order.receive, { id: this.id })
            .then(res => {
              if (res.code == 0) {
                return this.$axios.post(this.$api.order.refund, { id: this.id });
              } else {
                this.$message.error(res.msg);
              }
            })
            .then(res => {
              if (res.code == 0) {
                this.getRefundInfo();
                this.$message({
                  message: '已确认收货并退款',
                  type: 'success',
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      } else {
        this.handle_state = val;
        this.showHandleSales = !0;
      }
    },
    //获取售后信息
    getRefundInfo() {
      this.$axios.post(this.$api.order.refundInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result.info;
          let handle_state = info.handle_state;
          let handleState = '';
          let order_sn = info.order_sn.split('');
          let refund_sn = info.refund_sn.split('');
          info.reciver_info.combine_detail = info.reciver_info.combine_detail.replace(/\s*/g, '');
          if (Number(info.goods_idscount_num) > 0) {
            info.actual_price = Number(info.goods_pay_price) * Number(info.goods_idscount_num);
            info.actual_price = info.actual_price.toFixed(2);
          } else {
            info.actual_price = info.goods_pay_price;
          }
          for (let i = 4; i < order_sn.length; i += 4) {
            order_sn[i - 1] = order_sn[i - 1] + ' ';
          }
          for (let i = 4; i < refund_sn.length; i += 4) {
            refund_sn[i - 1] = refund_sn[i - 1] + ' ';
          }
          info.order_sn = order_sn.join('');
          info.refund_sn = refund_sn.join('');
          if (info.user_images) info.user_images = JSON.parse(info.user_images);
          if (handle_state == 0) {
            handleState = 1;
          }
          if (handle_state == 20 && info.refund_type == 2 && !info.tracking_no && !info.tracking_time) {
            handleState = 2;
          }
          if (handle_state == 20 && info.refund_type == 2 && info.tracking_no != null && info.tracking_time > 0 && info.receive == 1) {
            handleState = 3;
          }
          if (handle_state == 20 && info.refund_type == 2 && info.tracking_no != null && info.tracking_time > 0 && info.receive == 2) {
            handleState = 4;
          }
          if (handle_state == 30) {
            handleState = 5;
          }
          if (info.is_close == 1 && (handle_state == 50 || handle_state == 51)) {
            handleState = 6;
          }
          if (handle_state == 20 && info.refund_type == 1) {
            handleState = 7;
          }
          if (handle_state == 10) {
            handleState = 8;
          }
          if (info.refund_type == 1) {
            if (info.is_close == 0 && handle_state >= 20 && info.handle_time > 0) handleState = 9;
          } else {
            if (info.is_close == 0 && handle_state >= 20 && info.handle_time > 0 && !info.tracking_no && !info.tracking_time && info.receive == 2) handleState = 9;
          }
          this.status = handleState;
          this.goods_info = [];
          this.goods_info.push({
            goods_img: info.goods_img,
            goods_num: info.goods_num,
            goods_title: info.goods_title,
            spec_title: info.goods_spec.spec_title || '--',
            goods_price: info.goods_price,
            goods_pay_price: info.goods_pay_price,
          });
          this.info = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
